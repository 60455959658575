import $ from 'jquery'
import windowView from 'shared/view/window'

const getOffset = (element) => $(element).offset()

const viewport = {
    belowTheFold(element, settings = {}) {
        const fold = windowView.getHeight() + windowView.scrollTop()
        const offset = getOffset(element)
        return offset == null ? false : fold <= offset - (settings.threshold || 0)
    },

    aboveTheTop(element, settings = {}) {
        const top = windowView.scrollTop()
        const $element = $(element)
        const offset = getOffset(element)
        return offset == null ? false : top >= offset.top + $element.height() - (settings.threshold || 0)
    },

    rightOfScreen(element, settings = {}) {
        const right = windowView.getWidth() + windowView.scrollLeft()
        const offset = getOffset(element)
        return offset == null ? false : right <= offset.left - (settings.threshold || 0)
    },

    leftOfScreen(element, settings = {}) {
        const left = windowView.scrollLeft()
        const $element = $(element)
        const offset = getOffset(element)
        return offset == null ? false : left >= offset.left + $element.width() - (settings.threshold || 0)
    },

    inViewport(element, settings = {}) {
        if (getOffset(element) == null) {
            return false
        }

        return (
            !this.rightOfScreen(element, settings) &&
            !this.leftOfScreen(element, settings) &&
            !this.belowTheFold(element, settings) &&
            !this.aboveTheTop(element, settings)
        )
    },
}

export default viewport
