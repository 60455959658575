module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (search.loading) { 
__p+='\n    <div class="headerFlyout__wrap__spinner">\n        <div class="spinner u-blockCenter"></div>\n    </div>\n';
 } else { 
__p+='\n    ';
 if (search.totalResultCount) { 
__p+='\n        <div class="js-search-communities-viewport headerCommunityFlyout__wrap__headerFlyoutList">\n            <ul class="js-search-communities headerFlyoutList headerFlyoutList-noHover headerFlyoutList-noLastSeparator"></ul>\n        </div>\n    ';
 } else { 
__p+='\n        <div class="headerCommunityFlyout__wrap__flashMessage">\n            <div class="flashMessage flashMessage-warning">\n                Sorry, no community found with the city or country containing <b>»'+
((__t=( search.text ))==null?'':_.escape(__t))+
'«</b>. Please enter a different city or country or select a community from the options below.\n            </div>\n        </div>\n        <ul class="js-nearby-communities headerFlyoutList headerFlyoutList-noHover headerFlyoutList-noLastSeparator"></ul>\n    ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
