module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="headerFlyoutTitle">\n    <span class="headerFlyoutTitle__text">\n        Members <span class="js-user-counter-title headerFlyoutTitle__simpleCounter"></span>\n    </span>\n</div>\n';
 if (!initialized || (initialized && !text)) { 
__p+='\n    <div class="headerFlyout__empty">\n        Start typing to see results…\n    </div>\n';
 } else { 
__p+='\n    ';
 if (totalResultCount) { 
__p+='\n        <ul class="js-users-collection headerFlyoutList headerFlyoutList-noHover"></ul>\n        <a class="headerFlyoutMore headerFlyoutMore-noHover js-navigation-item js-users-more"\n            href="'+
((__t=( route.member_search_get + '?quickSearch=' + text + '&ref=he_amr' ))==null?'':_.escape(__t))+
'">\n            <i class="icon icon-forward-midBlue"></i>\n            See all results <span class="js-user-counter-link headerFlyoutTitle__simpleCounter u-quarterSpaceLeft"></span>\n        </a>\n    ';
 } else { 
__p+='\n        <div class="headerFlyout__empty">\n            No results in members\n        </div>\n    ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
