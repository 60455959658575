import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timeZone from 'dayjs-ext/plugin/timeZone'
import invariant from 'invariant'

dayjs.extend(utc)
dayjs.extend(timeZone)

// (.*) anything
// (\+|-) match "+" or "-" character
// (\d{2}) match 2 numerical characters
// (:?) optionally match ":" character
// (\d{2}) match 2 numerical characters
const iso8601TimezoneRegex = /(.*)(\+|-)(\d{2})(:?)(\d{2})$/

// see https://github.com/iamkun/dayjs/issues/796
// this function guarantees the millisecond part of the datestring
// will be AT MOST 3 digits long
// so that dayjs can parse it.
// NOTE: this will NOT WORK for dates with timezone info
export const trimMillisecondsFromDateString = (date) => {
    const split = date.split('.')
    if (split.length > 1 && split[1].length > 3) {
        return date.slice(0, date.length - Math.abs(3 - split[1].length))
    }
    return date
}

// checks for utc offset in a datestring, eg
// 2019-05-15T13:00:00+0400
export function doesDateHaveUTCOffset(date) {
    return date.match(iso8601TimezoneRegex) !== null
}

// if date has a timezone offset, remove it
export function stripTimezoneFromDate(date) {
    // if there is a timezone embedded in the string,
    if (doesDateHaveUTCOffset(date)) {
        // if the string has a ':' character,
        // need to trim 6 characters
        // otherwise, only 5 characters
        const numCharactersToTrim = date.match(iso8601TimezoneRegex).indexOf(':') > -1 ? -6 : -5
        // remove the timezone offset
        date = date.slice(0, numCharactersToTrim)
    } else if (date.slice(date.length - 1, date.length).toLowerCase() === 'z') {
        // remove UTC time shorthand "Z", "ZZ", or "z"
        date = date.replace(/z/gi, '')
    }

    return date
}

export function sanitizeInput(date, { stripTimezone }) {
    date = stripTimezone ? stripTimezoneFromDate(date) : date
    date = trimMillisecondsFromDateString(date)
    return date
}

/**
 * timezoneAgnosticFormatDateTime('2011-07-26T11:42:09+01:00')
 * // => '26 Jul 2011 11:42'
 */
export function timezoneAgnosticFormatDateTime(date) {
    date = sanitizeInput(date, { stripTimezone: true })
    return dayjs(date).format('DD MMM YYYY HH:mm')
}

/**
 * timezoneAgnosticFormatWeekday('2011-07-26T11:42:09+01:00')
 * // => 'Tue'
 */
export function timezoneAgnosticFormatWeekday(date) {
    date = sanitizeInput(date, { stripTimezone: true })
    return dayjs(date).format('ddd')
}

/**
 * timezoneAgnosticFormatTime('2011-07-26T11:42:09+01:00')
 * // => '11:42'
 */
export function timezoneAgnosticFormatTime(date) {
    date = sanitizeInput(date, { stripTimezone: true })
    return dayjs(date).format('HH:mm')
}

/**
 * timezoneAgnosticFormatDateShort('2011-07-26T11:42:09.000000Z')
 * // => '21 Jul'
 */
export function timezoneAgnosticFormatDateShort(date) {
    date = sanitizeInput(date, { stripTimezone: true })
    return dayjs(date).format('DD MMM')
}

/*
 * timezoneAgnosticFormatDateWeekday('2011-07-26T11:42:09.000000')
 * // => 'Mon 21 Jul'
 */
export function timezoneAgnosticFormatDateWeekday(date) {
    date = sanitizeInput(date, { stripTimezone: true })
    return dayjs(date).format('ddd DD MMM')
}

/**
 * timezoneAgnosticFormatDate('2011-07-26T11:42:09.000000')
 * => '21 Jul 2011'
 */
export function timezoneAgnosticFormatDate(date) {
    const currentDate = dayjs()
    date = sanitizeInput(date, { stripTimezone: true })
    const givenDate = dayjs(date)

    if (currentDate.diff(givenDate, 'days') > 365) {
        return givenDate.format('DD MMM YYYY')
    }
    return givenDate.format('DD MMM')
}

/**
 * timezoneAgnosticFormatDate('2011-07-26T11:42:09.000000')
 * => '2011'
 */
export function timezoneAgnosticFormatYear(date) {
    date = sanitizeInput(date, { stripTimezone: true })
    return dayjs(date).format('YYYY')
}

/**
 * localTimezoneFormatTime('2008-01-07T11:11:41.000000Z')
 * => '12:11' (in Europe/Berlin timezone)
 */
export function localTimezoneFormatTime(date) {
    return dayjs(date).local().format('HH:mm')
}

/*
 *  localTimezoneFormatDateShort('2011-07-26T11:42:09.000000')
 * // => '26 Jul'
 */
export function localTimezoneFormatDateShort(date) {
    return dayjs(date).local().format('DD MMM')
}

/**
 * utcFormatDateYear('2011-07-26T11:42:09.000000Z')
 * => '21 Jul 2011'
 */
export function utcFormatDateYear(date) {
    return dayjs(date).utc().format('DD MMM YYYY')
}

/**
 * utcFormatMonthYear('2011-07-26T11:42:09.000000Z')
 * => 'July 2011'
 */
export function utcFormatMonthYear(date) {
    return dayjs(date).utc().format('MMMM YYYY')
}

/**
 * localTimezoneFormatHumanDateTime('2011-07-26T11:42:09.000000')
 * (today)      // => '11:23'
 * (yesterday)  // => '21 Jul 2011 at 11:23'
 * (a year ago) // => '21 Jul 2011'
 */
export function localTimezoneFormatHumanDateTime(date) {
    const currentDate = dayjs()
    const givenDate = dayjs(date).local()

    if (currentDate.diff(givenDate, 'days') === 0) {
        return givenDate.format('HH:mm')
    }

    if (currentDate.diff(givenDate, 'years') >= 1) {
        return givenDate.format('DD MMM YYYY')
    }

    return `${givenDate.format('DD MMM')} at ${givenDate.format('HH:mm')}`
}

export function utcFormatYear(date) {
    return dayjs(date).utc().format('YYYY')
}

export function utcFormatDateWithPrecision({ dateTime, precision }) {
    invariant(
        dateTime && precision,
        'Trying to format a datetime with invalid parameters (dateTime = %s, precision = %s)',
        dateTime,
        precision
    )

    const date = dayjs(dateTime).utc()

    const PRECISION_FORMAT_MAP = {
        year: 'YYYY',
        month: 'MMMM',
        day: 'DD',
    }
    const PARTS_PRIORITY = ['day', 'month', 'year']
    const startingPoint = PARTS_PRIORITY.indexOf(precision)

    return PARTS_PRIORITY.slice(startingPoint)
        .map((part) => date.format(PRECISION_FORMAT_MAP[part]))
        .join(' ')
}
