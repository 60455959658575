;(function () {
    // from https://github.com/idmadj/element-closest-polyfill
    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
            var el = this

            do {
                if (el.matches) {
                    if (el.matches(s)) return el
                } else if (el.msMatchesSelector) {
                    if (el.msMatchesSelector(s)) return el
                }
                el = el.parentElement || el.parentNode
            } while (el !== null && el.nodeType === 1)
            return null
        }
    }
})()
