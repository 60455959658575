module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="headerSearchTextField__iconSearch">\n    <i class="icon icon-search-darkBlue icon-small"></i>\n</div>\n<div class="js-icon-cancel headerSearchTextField__iconCancel">\n    <i class="icon icon-crossRound-darkBlue icon-small"></i>\n</div>\n<div class="headerSearchTextField__spinner">\n    <div class="spinner spinner-tiny"></div>\n</div>\n\n<div class="headerSearchTextField__wrap__formField">\n    <div class="formField u-noMargin">\n        <input class="js-input formField__input"\n            name="'+
((__t=( options.name ))==null?'':_.escape(__t))+
'"\n            placeholder="'+
((__t=( options.placeholderText ))==null?'':_.escape(__t))+
'"\n            data-hj-suppress/>\n    </div>\n</div>\n';
}
return __p;
};
