module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (twinkles.loading) { 
__p+='\n    <div class="headerFlyout__headingWrapper">\n        <div class="headerFlyout__heading">\n            Members Saying Hi!\n        </div>\n        <a class="u-marginAutoLeft" href="'+
((__t=( link.allTwinkles ))==null?'':_.escape(__t))+
'">\n            View all<i class="icon icon-arrowright"></i>\n        </a>\n    </div>\n    <div class="headerFlyout__wrap__spinner">\n        <div class="spinner u-blockCenter"></div>\n    </div>\n';
 } else { 
__p+='\n    <div class="headerFlyout__headingWrapper">\n        <div class="headerFlyout__heading">\n            Members Saying Hi!\n        </div>\n        ';
 if (twinkles.total) { 
__p+='\n            <div class="js-count-header u-halfSpaceLeft"></div>\n        ';
 } 
__p+='\n        <a class="u-marginAutoLeft" href="'+
((__t=( link.allTwinkles ))==null?'':_.escape(__t))+
'">\n            View all<i class="icon icon-arrowright"></i>\n        </a>\n    </div>\n\n    ';
 if (twinkles.total) { 
__p+='\n        <ul class="js-twinkles-list headerFlyoutList"></ul>\n    ';
 } else { 
__p+='\n        <div class="headerFlyout__empty">Nobody said Hi! yet</div>\n    ';
 } 
__p+='\n';
 } 
__p+='\n';
}
return __p;
};
