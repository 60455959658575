module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="userPreview userPreview-microUser userPreview-'+
((__t=( size ))==null?'':_.escape(__t))+
'\n            ';
 if (showOverlay) { 
__p+=' userPreview-withOverlay';
 } 
__p+='">\n\n    <div class="js-component-user-image-link"></div>\n\n    ';
 if (flags) { 
__p+='\n        <div class="js-component-flags"></div>\n    ';
 } 
__p+='\n</div>\n';
}
return __p;
};
