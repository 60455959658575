module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 /* allowHtmlVariables message */ 
__p+='\n<div class="stickyMessage__wrap__flashMessage">\n    '+
((__t=( message ))==null?'':__t)+
'\n</div>\n';
}
return __p;
};
